import React from 'react';
import ai from '../../assets/R.png';
import './footer.css'; // Import your CSS file

const Footer = () => (
  <div className="footer section-padding "  >
    <div className="footer-content" id="contact">
      <div className="footer-logo">
        <img src={ai} alt="gpt3_logo" />
        <div className="footer-links">
        <h4 >Entrer en contact</h4>
        <p>Instagram: <a href="https://www.instagram.com/wind_owscleaner?igsh=NTc4MTIwNjQ2YQ==">Wind_owscleaner</a></p>
        <p>watsup : +15149742314</p>
      </div>
      </div>

     
    </div>

    <div className="footer-copyright">
      <p>@2024 All rights reserved by <a href="https://www.linkedin.com/in/boularakoussama/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app&fbclid=IwAR0CCl54MXMhIq1QcZsvHytIZLZ5aqBrC62jEXceEtCZwA1e3gACjs3wu0c">BreakerOZ</a> </p>
    </div>
  </div>
);

export default Footer;
