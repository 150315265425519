import React from 'react';
import washer from '../../assets/wash.png';

import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" >
   
    <div className="gpt3__possibility-image">
      <img src={washer} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content" id="possibility">
      <h1 className="gradient__text"> <br /> </h1>
      <p>Découvrez la différence de fenêtres et de vitres méticuleusement nettoyées. Que ce soit pour éliminer la saleté tenace, enlever les traces de doigts ou rendre vos fenêtres étincelantes pour une occasion spéciale, nous offrons une gamme complète de services de nettoyage de vitres adaptés à vos besoins. Profitez d'un service professionnel et d'une qualité exceptionnelle à chaque visite."</p>
    </div>
    
  </div>
);

export default Possibility;
