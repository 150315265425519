import React, { useState } from 'react';
import ai from '../../assets/R.png';
import './navbar.css';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={ai} alt="logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          <p><a href="#home">accueil</a></p>
          <p><a href="#footer">Services</a></p>
          <p><a href="#contact">contact</a></p>
        </div>
        <div className="gpt3__navbar-menu">
          <button onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z" />
            </svg>
          </button>
          {showMenu && (
            <div className="gpt3__navbar-menu_container">
              <p><a href="#home">accueil</a></p>
              <p><a href="#footer">Services</a></p>
              <p><a href="#contact">contact</a></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
