import React from 'react';
import Feature from '../../components/feature/Feature';
import './wglass.css'; // Keep your CSS file
import Header  from '../header/Header';
import Footer from '../footer/Footer';
import Possibility from '../possibility/Possibility';

 const Wglass = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
          <Header />
          <Possibility />
          <div className="gpt3__whatgpt3-container">
      <Feature title="Garantie sans traces" text="Nous garantissons des résultats impeccables, laissant vos fenêtres et surfaces en verre impeccables." />
      <Feature title="Expertise intérieure et extérieure" text="Des maisons résidentielles aux immeubles de grande hauteur, nous nettoyons avec expertise tous types de fenêtres et de verre." />
      <Feature title="Solutions écologiques" text="Nous privilégions des produits de nettoyage sûrs et respectueux de l'environnement pour votre tranquillité d'esprit." />
</div>
    <Footer />

  </div>
);

export default Wglass;
