import React from 'react';
import { Wglass  } from './containers';
import { Navbar } from './components';

import './App.css';

function App() {
  return (
    <div className="App">
   <div className="gradient__bg">
      {/* <Navbar />     */}
      <Navbar />

      <Wglass />
    </div>
    {/* <Footer /> */}
    </div>

  );
}

export default App;
